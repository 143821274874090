<script lang="ts" setup>
import { toast } from "vue3-toastify";
const props = withDefaults(
	defineProps<{
		enabledPopup?: boolean;
		closeByClickOutside?: boolean;
	}>(),
	{
		enabledPopup: true,
		closeByClickOutside: false
	}
);

const { isOpen } = useAppModals();
const {
	unreadNotifications,
	readNotifications,
	isActiveAnimation,
	showNotificationPopup,
	handleClickNotificationMessage,
	setNotificationsStatusAsRead,
	updateNotificationsStatusAsRead,
	openPopup
} = useNotificationCenter({
	isOpen,
	toastOptions: {
		containerId: "notification-center-container",
		position: toast.POSITION.TOP_RIGHT
	}
});
const notificationCenter = ref();

const unreadNotificationsCounter = computed(() =>
	unreadNotifications.value.length > 9 ? "9+" : unreadNotifications.value.length
);

onClickOutside(notificationCenter, () => {
	if (!props.closeByClickOutside) {
		return;
	}

	showNotificationPopup.value = false;
});
</script>
<template>
	<div class="notification-center" :class="{ active: isActiveAnimation }">
		<div class="ring" @click="openPopup">
			<NuxtIcon class="icon" name="24/notification-mail" filled @click="openPopup" />
			<AText
				v-if="unreadNotifications?.length"
				as="div"
				variant="tallinn"
				:modifiers="['bold']"
				class="unread-count text-coro"
			>
				{{ unreadNotificationsCounter }}
			</AText>
		</div>

		<AOverlay v-if="showNotificationPopup && enabledPopup" :modifiers="['fixed']" :hideOverflow="true">
			<ONotificationCenterPopup
				ref="notificationCenter"
				:unread-notifications="unreadNotifications"
				:read-notifications="readNotifications"
				@set-status-read="setNotificationsStatusAsRead"
				@click-on-message="handleClickNotificationMessage"
				@update-notification-status="updateNotificationsStatusAsRead"
				@close-popup="showNotificationPopup = false"
			/>
		</AOverlay>
	</div>
</template>
<style scoped lang="scss">
.overlay {
	z-index: 24;
}
.notification-center {
	position: relative;
	margin-right: 10px;

	.icon {
		filter: grayscale(50%);
		@include media-breakpoint-down(lg) {
			font-size: 30px;
		}
	}

	&.active {
		.icon {
			animation: mail-rotate 4s infinite;
		}
	}

	.ring {
		border-radius: 999px;
		width: 54px;
		height: 38px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: var(--carabanchel);
		border: 2px solid var(--carletonville);
		cursor: pointer;

		@include media-breakpoint-down(lg) {
			background: none;
			border: none;
		}
	}

	&:deep(.overlay) {
		display: block;
	}

	&:deep(.popper) {
		background-color: var(--carabanchel);
		z-index: 10;
		padding: 0;
		position: fixed !important;
		transform: none !important;
		left: auto !important;
		right: 0 !important;
		top: 0 !important;
		width: 360px;
		height: 100dvh;
		overflow-y: auto;
		overflow-x: hidden;

		&:hover {
			background-color: var(--carabanchel);
		}

		@include media-breakpoint-down(lg) {
			border: none;
			border-radius: 0;
			position: fixed !important;
			left: 0 !important;
			top: 0 !important;
			right: auto !important;
			width: 100%;
			max-width: 100%;

			@media (orientation: landscape) {
				top: 0 !important;
			}
		}
	}
}

.icon {
	font-size: 20px;

	&:deep(svg) {
		margin-bottom: 0;
	}
}

.unread-count {
	position: absolute;
	top: 2px;
	right: 10px;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	background-color: var(--coyoacan);
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

@keyframes mail-rotate {
	0% {
		transform: rotate(0deg);
	}
	80% {
		transform: rotate(0deg);
	}
	85% {
		transform: rotate(-10deg);
	}
	95% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
</style>
